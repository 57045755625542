import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import { formatDistance } from "date-fns";
import { Carousel } from "react-responsive-carousel";
import { Link } from "gatsby";
import contentfulClient from "../../utils/contentful-helper";
import Layout from "../../components/layout";
import { getURLParams } from "../../utils/common-helper";
import CallIcon from "../../assets/images/icons/call.svg";
import BackIcon from "../../assets/images/icons/arrow-back.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/pages/properties.scss";

export default function PropertyDetails({ location }) {
  const id = getURLParams(location.search, "id");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  console.log("prop data", data);
  const bedrooms = _get(data, "fields.bedrooms", 0);
  const bathrooms = _get(data, "fields.bathrooms", 0);
  const availability = _get(data, "fields.availability", "");
  const propertyType = _get(data, "fields.propertyType", "");
  const price = _get(data, "fields.price", "");
  const area = _get(data, "fields.area", "");
  const furnishing = _get(data, "fields.furnishing", "");
  const description = _get(data, "fields.description", "");
  const street = _get(data, "fields.street", "");
  const doorNumber = _get(data, "fields.doorNumber", "");
  const buildingName = _get(data, "fields.buildingName", "");
  const phoneNumber = _get(data, "fields.phoneNumber", "");
  const createdOn = formatDistance(
    new Date(_get(data, "sys.createdAt", 1)),
    new Date(),
    {
      addSuffix: true,
    }
  );
  const getData = () => {
    setLoading(true);
    return contentfulClient
      .getEntry(id)
      .then((d) => {
        setData({ ...d });
      })
      .catch(() => {
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Properties for Rental, Lease and Sale">
      <Link className="back-link" to={`/properties`}>
        <h1 className="page-title">
          <img src={BackIcon} alt="back-button" />
          <span>Back to All Properties</span>
        </h1>
      </Link>
      {loading ? (
        <div className="loading">
          <p>Loading, please wait!</p>
        </div>
      ) : (
        <div className="property-details">
          <Carousel showArrows={false} className="product-gallery">
            {_get(data, "fields.gallery", []).map((g) => {
              return (
                <div>
                  <img
                    src={_get(g, "fields.file.url", "")}
                    alt={_get(g, "fields.title", "")}
                  />
                  <p className="legend">{_get(g, "fields.title", "")}</p>
                </div>
              );
            })}
          </Carousel>
          <div className="product-info">
            <h1>
              {`${bedrooms}BHK ${propertyType} for ${availability} at ₹${price} p/m`}
            </h1>
            <p className="product-timestamp">Added {createdOn}</p>
            <ul>
              <li>
                <div className="product-info-item">
                  <span>Type: </span>
                  <span>{propertyType}</span>
                </div>
              </li>
              <li>
                <div className="product-info-item">
                  <span>Availability: </span>
                  <span>{availability}</span>
                </div>
              </li>
              <li>
                <div className="product-info-item">
                  <span>Bedrooms: </span>
                  <span>{bedrooms}</span>
                </div>
              </li>
              <li>
                <div className="product-info-item">
                  <span>Bathrooms: </span>
                  <span>{bathrooms}</span>
                </div>
              </li>
              <li>
                <div className="product-info-item">
                  <span>Area: </span>
                  <span>{`${area} sq.ft`}</span>
                </div>
              </li>
              <li>
                <div className="product-info-item">
                  <span>Furnishing: </span>
                  <span>{furnishing}</span>
                </div>
              </li>
              <li>
                <div className="product-info-item">
                  <span>Location: </span>
                  <span>
                    {doorNumber}, {buildingName}, {street}
                  </span>
                </div>
              </li>
              <li>
                <div className="product-info-item">
                  <span>Price: </span>
                  <span>
                    ₹{price}{" "}
                    {availability === "rent"
                      ? "per month"
                      : availability === "lease"
                      ? "per year"
                      : ""}
                  </span>
                </div>
              </li>
              {description && (
                <li>
                  <div className="product-info-item">
                    <span>More Info: </span>
                    <span>{description}</span>
                  </div>
                </li>
              )}
            </ul>
            <div className="product-action">
              <a href={`tel:+91${phoneNumber}`}>
                <div>
                  <img src={CallIcon} />
                </div>
                <div>
                  <p>Contact Owner at</p>
                  <p>{`+91-${phoneNumber}`}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
